.status-operation-dropdown button{
    font-size: 10px !important;
    padding: 3px !important;
    border-radius: 5px;
}

.status-operation-dropdown .dropdown-item-wrapper button{
    font-size: 10px !important;
    padding: 3px !important;
    border-radius: 5px;
    color: white !important;
}