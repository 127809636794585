//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.fs-7 {
	font-size: 11.25px;
}

.fs-8 {
	font-size: 9px;
}

.fs-status {
  font-size: 7px;
}

.hrStyle {
	text-align: center;
	font-size: 3rem;
	color: #E85359;
	font-family: 'Cinzel Decorative', serif;
	-webkit-font-feature-settings: "clig", "dlig", "hlig", "liga", "swsh", "onum";
	-moz-font-feature-settings: "clig", "dlig", "hlig", "liga", "swsh", "onum";
	font-feature-settings: "clig", "dlig", "hlig", "liga", "swsh";
	padding-top: 1rem;
}

.hrStyle:before,
.hrStyle:after,
.hrStyle hr:before,

.hrStyle hr:after {
	content: '';
	background: whitesmoke;
	width: 22px;
	height: 22px;
	border: 2px solid #e22f65;
	position: absolute;
	bottom: 0;
	transform: rotate(45deg) translatey(calc(50% + 3px));
	transform-origin: center;
	left: calc(5% + 11px);
	z-index: 2;
	border-radius: 2px;
}

.hrStyle:after {
	left: auto;
	right: calc(5% - 21px);
}

.hrStyle hr:before {
	left: 0;
}

.hrStyle hr:after {
	left: auto;
	right: -11px;
}

.hrStyle hr {
	width: 91%;
	position: absolute;
	left: 5%;
	bottom: 0;
	background: #ffcf52;
	height: 3px;
}
.position {
	margin: 0; padding: 0; border: 0 none; position: relative;
}

.alert {
	flex-wrap: nowrap;
}


.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #5d8540;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #979a9e;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  box-sizing: border-box;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

.thumb::-webkit-slider-thumb {
  background-image: linear-gradient(to bottom right, #ffcf52, #e22f65);;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 1px 1px linear-gradient(to bottom right, #ffcf52, #e22f65);;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  transform: rotate(45deg);
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.PhoneInputInput {
  border: none;
  background: transparent;
  padding-left: 5px;
}
.PhoneInputInput:focus {
  outline: none;
}

.PhoneInputInput:focus > .PhoneInput {
  outline: 2px;
}

.min-h-500 {
  min-height: 500px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.max-h-150 {
  max-height: 150px;
}

.max-h-250 {
  max-height: 250px;
}

.max-h-325 {
  max-height: 325px;
}

.max-h-400 {
  max-height: 400px;
}

.max-h-500 {
  max-height: 500px;
}

.max-h-600 {
  max-height: 600px;
}


.min-w-30px {
  min-width: 50px;
}

.navigation-text{
  font-size: 11px;
}

.text-blue-600{
  color: #1E88E5 !important;
}

.text-orange-500{
  color: #FF9800 !important;
}

.fs-9{
  font-size: 8px;
}

.footer {
  margin-top: 20px;
}

.react-tel-input .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.react-tel-input:has(.is-invalid) + .invalid-feedback {
  display: block;
}

.col-3-custom {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 26%;
}

img.rounded-circle {
  object-fit: cover;
}

.bg-l25-storybook {
  background-color: lighten($storybook, 30%);
}

.recent-activity-max-width {
  max-width: 2000px; /* Valor predeterminado */
  @media (min-width: 768px) {
    max-width: 900px; /* Cambia a 1000px cuando sea mayor que md */
  }
}

.min-w-custom {
  min-width: 100px;
}

.text-clawback {
  color: #e22f65 !important;
  font-weight: bold;
  border: #e22f65 solid 1px;
  padding: 5px;
  border-radius: 5px;
}

.text-renewal {
  color: #ffcf52 !important;
  font-weight: bold;
  border: #ffcf52 solid 1px;
  padding: 5px;
  border-radius: 5px;
}

.text-created {
  color: #5d8540 !important;
  font-weight: bold;
  border: #5d8540 solid 1px;
  padding: 5px;
  border-radius: 5px;
}

.add-button {
  border: 2px solid ;
  padding: 7;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
}

.add-button:hover {
  color: #5d8540;
  background-color: #eff3ec;
  border-color: #5d8540;
}

.maxWidth-rounded-config-button {
  max-width: 41px !important;
}

.statusButtons {
  max-width: 100px;
  min-width: 100px;
}

.element-configuration {
  border-radius: 50px ;
  border: 1px solid transparent;
  border-image: linear-gradient(45deg, var(--color-primary), var(--color-secondary)) border-box;
  -webkit-mask-composite: xor;
          mask-composite: exclude;
}

.w-150 {
  width: 300px !important;
}

.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.with-90 {
  width: 90% !important;
}

.w-webkit-100 {
  width: -webkit-fill-available !important;
}

.css-t3ipsp-control:hover {
  border-color: #384F26 !important;
  box-shadow: 0 0 1px #384F26 !important;
}

.color-info-danger { 
  width: 20px;
  height: 20px;
  background-color: #e22f65;
}

.color-info-success { 
  width: 20px;
  height: 20px;
  background-color: #44BCA9;
}

.color-info-particular { 
  width: 20px;
  height: 20px;
  background-color: #5DADE2;
}

.color-info-business { 
  width: 20px;
  height: 20px;
  background-color: #F39C12;
}

.bg-business {
  background-color: #F39C12 !important;
}

.bg-particular {
  background-color: #5DADE2 !important;
}

.w-10 {
  width: 10% !important;
}