.loader-animation-container {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.loader-content {
    font-size: 1.14em;
    font-weight: 400;
}
